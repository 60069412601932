import React, {useState} from 'react'
import "./docsGpt.scss";
function DocsGpt() {
  return (
      <div className='docsGptComp'>
          <div className="docsGptHeading">
              
          5thplusGpt
          </div>
      </div>
  )
}

export default DocsGpt