import { useState } from "react";
import fifthplusbg from "../../assets/5thplus_Background.mp4";
import "./auth.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post("http://localhost:8000/auth/signin", {
        data: { email, password },
      });
      navigate("/devdashboard", { state: { token: res.data.token } });
      alert("Logged in Succesfully!");
    } catch (e) {
      alert("Error Logging in");
    }
  };

  return (
    <div className="register">
      <video src={fifthplusbg} autoPlay loop muted />
      <div className="formcontainer">
        <form className="form">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            placeholder="Enter Your Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label htmlFor="pass">Password:</label>
          <input
            type="password"
            id="pass"
            placeholder="Enter Your Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
