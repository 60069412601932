import React, { useState } from "react";
import "./about.scss";
import img1 from "../../assets/5thplus11.png";
import img2 from "../../assets/with5thplusainobgf.png";
import img3 from "../../assets/without5thplusyc.png";
function About() {
  const [w5th, setW5th] = useState(true);
  const w5thplus = () => {
    if (!w5th) {
      setW5th(true);
    }
  };
  const wi5thplus = () => {
    if (w5th) {
      setW5th(false);
    }
  };
  return (
    <div className="about" id="about">
      <div className="aboutCont">
        <div className="overlay2"></div>
        <div className="overlay"></div>
        <div className="overlay1"></div>
        <img src={img1} alt="" />
        <div className="aboutHeader">
          <div className="aboutHeaderTop">
            <div
              className="with5thplus"
              onClick={w5thplus}
              style={{
                color: `${w5th ? "#6434b8" : "#eee"}`,
                borderBottom: `${w5th ? "1px solid #eee" : "none"}`,
              }}
            >
              {" "}
              With Dev++
            </div>
            <div
              className="without5thplus"
              onClick={wi5thplus}
              style={{
                color: `${w5th ? "#eee" : "#6434b8"}`,
                borderBottom: `${w5th ? "none" : "1px solid #eee"}`,
              }}
            >
              Without Dev++
            </div>
          </div>

          {w5th ? (
            <div className="aboutLeft">
              <img src={img2} alt="" />
            </div>
          ) : (
            <div className="aboutRight">
              <img src={img3} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
