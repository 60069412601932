import "./profileCard.scss";
import { BsGithub, BsLinkedin, BsTwitter } from "react-icons/bs";

const ProfileCard = ({ role, name, email }) => {
  return (
    <div className="profile-card">
      <div className="profile-picture">
        <img
          srcSet="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNVtob03XCeFw6vd1_mUf14S43IFCoK5NvCw&usqp=CAU"
          alt="Your Name"
        />
      </div>
      <h1 className="name">{name}</h1>
      <p className="job-title">{role}</p>
      <p>{email}</p>
      <div className="social-links">
        <BsTwitter
          className="twitter"
          style={{
            color: "#00acee",
            margin: "15px",
            width: "30px",
            height: "40px",
          }}
        />
        <BsLinkedin
          className="linkedin"
          style={{
            color: "#0A66C2",
            width: "30px",
            margin: "15px",
            height: "40px",
          }}
        />
        <BsGithub
          className="github"
          style={{
            color: "#4078c0",
            width: "30px",
            margin: "15px",
            height: "40px",
          }}
        />
      </div>
    </div>
  );
};

export default ProfileCard;
