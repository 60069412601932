import React, { useState } from "react";
import fifthplusbg from "../assets/5thplus_Background.mp4";
import logo from "../assets/devplusplus logo.png";
import { useNavigate } from "react-router-dom";

function Main() {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  return (
    <div className="main">
      <div className="overlay"></div>
      <div className="overlay1"></div>
      <video src={fifthplusbg} autoPlay loop muted />
      <div className="container">
        <div className="top">
          <div className="top-left">
            <img src={logo} alt="" srcSet="" className="logo" />
            <div className="logoname">
              {" "}
              <a href="#">Dev++</a>
            </div>
          </div>
          <div className="top-middle">
            <div className="about right-font">
              <a href="#about">About</a>{" "}
            </div>
            <div className="team right-font">
              <a href="#team">Team</a>{" "}
            </div>
            <div className="community right-font">
              <a href="#community">Community</a>{" "}
            </div>
          </div>
          <div className="top-right">
            <div className="sust">
              <button
                onClick={() => navigate("/login")}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                }}
              >
                <div className="sustainability right-font">Signin</div>
              </button>
              <div
                className="testnet right-font"
                onClick={() => navigate("/playground")}
              >
               Studio
              </div>
            </div>
          </div>
        </div>
        <div className="middle">
          <div className="middle-top">
            <div className="middle-top-text">AI-Powered</div>
            <span>End to End App Development Platform</span>
          </div>
          <div className="middle-bottom">
            A platform where you can receive Services, Complete Development,
            Deployment, and ultimately Deliver the Product.
          </div>
        </div>
        <div className="bottom">
          <div className="bottom-bottom">
            <form>
              <input
                type="email"
                name="user_email"
                id=""
                placeholder="Enter Your E-Mail Address"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={() => {
                  navigate("/register", { state: { email: email } });
                }}
              >
                Get Started For Free
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
