import "./sidebar.scss";
import {
  LineStyle,
  Timeline,
  PermIdentity,
  Storefront,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Code,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

const Sidebar = ({ role }) => {
  console.log(role);
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className="sidebarListItem active">
                <LineStyle className="sidebarIcon" />
                Home
              </li>
            </Link>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/" className="link" style={{ color: "white" }}>
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Projects
              </li>
            </Link>
            <Link to="/" className="link" style={{ color: "white" }}>
              <li className="sidebarListItem">
                <Storefront className="sidebarIcon" />
                Developers
              </li>
            </Link>
            {role !== "Client" && (
              <Link to="/" className="link" style={{ color: "white" }}>
                <li className="sidebarListItem">
                  <Code className="sidebarIcon" />
                  IDE
                </li>
              </Link>
            )}
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <MailOutline className="sidebarIcon" />
              Mail
            </li>
            <li className="sidebarListItem">
              <DynamicFeed className="sidebarIcon" />
              Feedback
            </li>
            <li className="sidebarListItem">
              <ChatBubbleOutline className="sidebarIcon" />
              Messages
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Manage</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              Manage Contributers
            </li>
            <li className="sidebarListItem">
              <Storefront className="sidebarIcon" />
              Manage Projects
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
