import React, { useState } from "react";
import "./community.scss";
import img2 from "../../assets/5thplus11.png";
import img3 from "../../assets/1rdteam.png";
import img4 from "../../assets/2rdteam.png";
import img5 from "../../assets/3rdteam.png";
import {
  BsGithub,
  BsLinkedin,
    BsTwitter,
    BsTelegram,
    BsDiscord,
    BsChat,
  BsChatDots
} from "react-icons/bs";
function Community() {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  const divStyles1 = {
    // backgroundColor: isHovered1 ? "blue" : "red", // Change the background color on hover
    color: "white",
    padding: "10px",
    transition: "background-color 0.3s ease", // Add a smooth transition effect
  };
  const divStyles2 = {
    // backgroundColor: isHovered2 ? "blue" : "red", // Change the background color on hover
    color: "white",
    padding: "10px",
    transition: "background-color 0.3s ease", // Add a smooth transition effect
  };
  const divStyles3 = {
    // backgroundColor: isHovered3 ? "blue" : "red", // Change the background color on hover
    color: "white",
    padding: "10px",
    transition: "background-color 0.3s ease", // Add a smooth transition effect
  };
  return (
    <div className="community" id="community">
      <div className="communityCont">
        <div className="overlay"></div>
        <div className="overlay1"></div>
        <img src={img2} alt="" className="img-back-team" />
        <div className="communityCont-content">
          <div className="communityCont-heading">
            <div className="communityCont-heading-top">Community</div>
            <div className="communityCont-heading-bottom">
              Building Bridges, Creating Bonds: Welcome to 5thplus Community.
            </div>
          </div>
          <div className="communityCont-card">
            <div className="communityCont-card-left card-team">
              <div
                className="e-card"
                style={divStyles1}
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
              >
                {/* {isHovered1 ? ( */}
                    <a href="https://discord.gg/QQmXZQ98mG">
                <div className="img-hovered">
                  <div className="img-hovered-down">
                      <BsDiscord
                        className="discord"
                        style={{
                          color: "#7289d9",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                  </div>
                  <div className="img-hovered-mid">Discord</div>
                  <div className="img-hovered-up">
                    Discover New Horizons: Join Our Discord Community!
                  </div>
                </div>
                    </a>
                {/* ) : ( */}
                {/* <>
                    <div className="e-card-image">
                      <img src={img3} alt="" className="e-card-class" />
                    </div>
                    <div className="e-card-content">Amit Kumar</div>
                  </> */}
                {/* )} */}
              </div>
            </div>
            <div className="communityCont-card-middle card-team">
              <div
                className="e-card"
                style={divStyles2}
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
              >
                {/* {isHovered2 ? ( */}
                    <a href="https://t.me/exitalcommunity">
                <div className="img-hovered">
                  <div className="img-hovered-down">
                      <BsTelegram
                        className="telegram"
                        style={{
                          color: "#229ED9",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                  </div>
                  <div className="img-hovered-mid">Telegram</div>
                  <div className="img-hovered-up">
                    Get In on the Action: Join Our Telegram Community!
                  </div>
                </div>
                    </a>
                {/* ) : (
                  <>
                    <div className="e-card-image">
                      <img src={img4} alt="" className="e-card-class" />
                    </div>
                    <div className="e-card-content">Yatharth</div>
                  </>
                )} */}
              </div>
            </div>
            <div className="communityCont-card-right card-team">
              <div
                className="e-card"
                style={divStyles3}
                onMouseEnter={handleMouseEnter3}
                onMouseLeave={handleMouseLeave3}
              >
                {/* {isHovered3 ? ( */}
                    <a href="https://twitter.com/amitkumariitm">
                <div className="img-hovered">
                  <div className="img-hovered-down">
                      <BsChatDots
                        className="twitter"
                        style={{
                          color: "blue",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                  </div>
                  <div className="img-hovered-mid">Dev++ Chat</div>
                  <div className="img-hovered-up">
                    Connect and Create with Fellow Developers!
                  </div>
                </div>
                    </a>
                {/* ) : (
                  <>
                    <div className="e-card-image">
                      <img src={img5} alt="" className="e-card-class" />
                    </div>
                    <div className="e-card-content">Apply to Join</div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
