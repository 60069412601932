import React from "react";
import logo from "../../assets/5thplus logo.png";
import "./footer.scss";
import {
  BsGithub,
  BsYoutube,
  BsLinkedin,
  BsTwitter,
  BsTelegram,
  BsDiscord,
} from "react-icons/bs";

export default function Footer() {
  return (
    <div className="fotter" id="fotter">
      <div className="bottom-container">
        <div className="bottom-container-top">
          <div className="bottom-container-top-left">
            <div className="top-left">
              <div className="top-left-top">
                <img src={logo} alt="" srcSet="" className="logo" />
                <div className="logoname">5thplus</div>
              </div>
              <div className="top-left-bottom">
                <div className="text1 text">● Address: IIT Madras, Chennai, Pin: 600036, India</div>
                <div className="email text">● Email: 5thpluschain@gmail.com</div>
                <div className="text2 text">● Contact: +91 9958158847</div>
                <div className="text2 text">● Contact: +91 6202721534</div>
              </div>
            </div>
          </div>
          <div className="bottom-container-top-right">
            <div className="top-right-top">
              Join Our Developers Community
            </div>
            <div className="top-right">
              <a href="https://twitter.com/5thplusChain">
                <BsTwitter
                  className="twitter"
                  style={{
                    color: "#00acee",
                    width: "28px",
                    height: "28px",
                  }}
                />
              </a>

              <a href="https://youtube.com/@5thplusChain">
                <BsYoutube
                  className="youtube"
                  style={{
                    color: "#c4302b",
                    width: "30px",
                    height: "30px",
                  }}
                />
              </a>
              <a href="https://discord.gg/QQmXZQ98mG">
                <BsDiscord
                  className="discord"
                  style={{
                    color: "#7289d9",
                    width: "23px",
                    height: "23px",
                  }}
                />
              </a>
              <a href="https://www.linkedin.com/company/5thpluschain/">
                <BsLinkedin
                  className="linkedin"
                  style={{
                    color: "#0A66C2",
                    width: "23px",
                    height: "23px",
                  }}
                />
              </a>
              <a href="https://github.com/5thplus">
                <BsGithub
                  className="github"
                  style={{
                    color: "#4078c0",
                    width: "28px",
                    height: "28px",
                  }}
                />
              </a>
             
              <a href="https://t.me/exitalcommunity">
                <BsTelegram
                  className="telegram"
                  style={{
                    color: "#229ED9",
                    width: "25px",
                    height: "25px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-container-bottom">
          <h6>Copyright © Devplusplus - All rights reserved 2023</h6>
        </div>
      </div>
    </div>
  );
}
