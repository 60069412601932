export const userData = [
  {
    name: "Jan",
    "Gas Fee": 4000,
  },
  {
    name: "Feb",
    "Gas Fee": 3000,
  },
  {
    name: "Mar",
    "Gas Fee": 5000,
  },
  {
    name: "Apr",
    "Gas Fee": 4000,
  },
  {
    name: "May",
    "Gas Fee": 3000,
  },
  {
    name: "Jun",
    "Gas Fee": 2000,
  },
  {
    name: "Jul",
    "Gas Fee": 4000,
  },
  {
    name: "Agu",
    "Gas Fee": 3000,
  },
  {
    name: "Sep",
    "Gas Fee": 4000,
  },
  {
    name: "Oct",
    "Gas Fee": 1000,
  },
  {
    name: "Nov",
    "Gas Fee": 4000,
  },
  {
    name: "Dec",
    "Gas Fee": 3000,
  },
];

export const productData = [
  {
    name: "Jan",
    Sales: 4000,
  },
  {
    name: "Feb",
    Sales: 3000,
  },
  {
    name: "Mar",
    Sales: 5000,
  },
];

export const userRows = [
  {
    id: 1,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 2,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 3,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 4,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 5,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 6,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 7,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 8,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 9,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
  {
    id: 10,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "jon@gmail.com",
    date: "2 Jan 2020",
  },
];

export const productRows = [
  {
    id: 1,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Series",
    limit: "16",
  },

  {
    id: 2,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Movie",
    limit: "18",
  },
  {
    id: 3,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Movie",
    limit: "18",
  },
  {
    id: 4,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Movie",
    limit: "18",
  },
  {
    id: 5,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Movie",
    limit: "18",
  },
  {
    id: 6,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Movie",
    limit: "18",
  },
  {
    id: 7,
    title: "Avenges",
    img: "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    year: 2023,
    type: "Movie",
    limit: "18",
  },
];
