import "./App.css";
import Footer from "./components/footer/Footer";
import DevDashboard  from "./pages/profile/Dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About";
import Main from "./components/Main";
import Team from "./components/team/Team";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Community from "./components/community/Community";
import MyEmbeddedWebsite from "./pages/SolPlayground/MyEmbeddedWebsite";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div className="homepage">
                <Main />
                <About />
                <Team />
                <Community />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/playground"
            element={
              <div className="solPlayGround">
                <MyEmbeddedWebsite />
              </div>
            }
          />
          <Route
            path="/devdashboard"
            element={
              <DevDashboard
                role="Developer"
                name="Amit Kumar"
                heading_1="Your Commits!"
                heading_2="Revenue!"
              />
            }
          />
          <Route
            path="/clientdashboard"
            element={
              <DevDashboard
                role="Client"
                name="Yatharath"
                heading_1="Projects Proposed!"
                heading_2="Revenue!"
              />
            }
          />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
