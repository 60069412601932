import { useState } from "react";
import fifthplusbg from "../../assets/5thplus_Background.mp4";
import "./auth.scss";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

export default function Register() {
  const { state } = useLocation();
  const email = state.email;
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post("https://dev.5thplus.com/api/auth/signup", {
        data: { email, password, name, role },
      });
      navigate("/devdashboard", { state: { token: res.data.token } });
      alert("Registered Succesfully!");
    } catch (e) {
      alert("Error Logging in");
    }
  };

  return (
    <div className="register">
      <video src={fifthplusbg} autoPlay loop muted />
      <div className="formcontainer">
        <form className="form">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            placeholder="Enter Your Name"
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="pass">Password:</label>
          <input
            type="password"
            id="pass"
            placeholder="Enter Your Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Your Role:</label>
          <select onSelect={(e) => setRole(e.target.value)}>
            <option>Developer</option>
            <option>Client</option>
          </select>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
