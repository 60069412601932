import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./myEmbeddedWebsite.scss";
import DocsGpt from "../../components/docsGpt/DocsGpt";
const MyEmbeddedWebsite = () => {
  const navigate = useNavigate();
  const [openDocsGpt, setOpenDocsGpt] = useState(false);

  const docsGpt = () => {
    if (openDocsGpt===false) {
      setOpenDocsGpt(true);
    } else {
      setOpenDocsGpt(false);
    }
  }
  return (
    <div className="solPlayGround">
      {/* <h1>Your ReactJS Website</h1> */}
      <iframe
        title="Embedded Website"
        src="https://beta.solpg.io/"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      />

      {!openDocsGpt ? (
        <></>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            backgroundColor: "black",
            color: "black",
            // border: "none",
            zIndex: "100",
            cursor: "pointer",
            height: "calc(100vh - 50px)",
            width: "400px",
            border: "1px solid white",
              borderRadius: "8px",
             backgroundImage: "radial-gradient(farthest-corner at bottom right, #3a233f, transparent 500px)"
          }}
        >
          <DocsGpt />
        </div>
      )}
      <button
        onClick={docsGpt}
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          backgroundColor: "black",
          color: "white",
          // border: "none",
          zIndex: "100",
          cursor: "pointer",
        }}
        className="docsGpt"
      >
        {!openDocsGpt?"Ask 5thplusGpt?":"Close 5thplusGpt?"}
      </button>
    </div>
  );
};

export default MyEmbeddedWebsite;
