import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featured/FeaturedInfo";
import SideBar from "../../components/sidebar/Sidebar";
import "./dashboard.scss";
import axios from "axios";
import { userData } from "../../dummyData";
import RecentProject from "../../components/recent/RecentProject";
import RecentContributer from "../../components/recent/RecentContributer";
import ProfileCard from "../../components/profile/ProfileCard";
import { ArrowBack, Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DevDashboard = ({ heading_1, heading_2 }) => {
  const { state } = useLocation();
  const [name, setName] = useState("");
  const [role, setEmail] = useState("");
  const [email, setRole] = useState("");

  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await axios.get(
          "https://dev.5thplus.com/api/auth/getprofile",
          {
            headers: {
              Authorization: state.token,
            },
          }
        );
        setEmail(res.data.email);
        setName(res.data.name);
        setRole(res.data.role);
      } catch (e) {
        alert("Error while Fetching the Data");
      }
    };
    getProfile();
  }, []);

  return (
    <div className="_container">
      <SideBar role={role} />
      <div className="dashboard">
        <ArrowBack
          style={{
            color: "white",
            position: "absolute",
            height: "30px",
            width: "50px",
            top: "5px",
            left: "15px",
          }}
        />
        <Search
          style={{
            color: "white",
            position: "absolute",
            height: "30px",
            width: "50px",
            top: "5px",
            right: "20px",
          }}
        />
        <ProfileCard role={role} name={name} email={email} />
        <FeaturedInfo heading_1={heading_1} heading_2={heading_2} />
        <Chart data={userData} title="User Analytics" grid dataKey="Gas Fee" />
        <div className="homeWidgets">
          <RecentContributer />
          <RecentProject />
        </div>
      </div>
    </div>
  );
};

export default DevDashboard;
